import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"create_pages"},[_c('div',{staticClass:"my_container"},[_c('h1',[_vm._v("Create Page")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Page Head","outlined":""},model:{value:(_vm.formData.head),callback:function ($$v) {_vm.$set(_vm.formData, "head", $$v)},expression:"formData.head"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Page Name","outlined":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VFileInput,{attrs:{"color":"deep-purple accent-4","counter":"","placeholder":"Image","prepend-icon":"","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 2)?_c(VChip,{attrs:{"color":"deep-purple accent-4","dark":"","placeholder":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.formData.first_image),callback:function ($$v) {_vm.$set(_vm.formData, "first_image", $$v)},expression:"formData.first_image"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('vue-editor',{model:{value:(_vm.formData.first_section),callback:function ($$v) {_vm.$set(_vm.formData, "first_section", $$v)},expression:"formData.first_section"}})],1),_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading_form,"disabled":_vm.loading_form,"color":"blue-grey","outlined":""},on:{"click":_vm.submit}},[_vm._v(" Add "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }